





















































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import LawyerForm from './lawyer-form.vue';
import LawyerView from './lawyer-view.vue';
import CreatePayment from '@/views/lawyers/payment/create-form.vue';
import ImportEnabled from './import-enabled.vue';
import ImportLawyer from './import-lawyer.vue';

class PageLawyerRequest extends PageRequest {
    document: string = '';
    name: string = '';
    alive: boolean = null;
    condition: number = null;
}

@Component({
    components: { ListView, LawyerForm, FormBase, CreatePayment, ImportEnabled, ImportLawyer, LawyerView }
})
export default class Lawyers extends ListBase {
    entity: string = 'lawyer';
    pagerequest: PageLawyerRequest = new PageLawyerRequest();
    filterForm: boolean = true;
    paymentModelShow: boolean = false;
    modalViewShow: boolean = false;
    showImport: boolean = false;
    showImportNew: boolean = false;

    filterItems = [
        this.gItem({ type: 'text', prop: 'document', label: 'Buscar por Numero de Documento o Matricula', size: 4, onchange: this.afterSave, hideDetails: true }),
        this.gItem({ type: 'text', prop: 'name', label: 'Buscar por Nombres o Apellidos', size: 4, onchange: this.afterSave, hideDetails: true }),
        this.gItem({ type: 'object', prop: 'condition', label: 'Condicion', generalType: 'L_CONDITION', size: 2, onchange: this.afterSave, hideDetails: true }),
        this.gItem({ type: 'switch', prop: 'alive', label: 'Vive?', size: 2, onchange: this.afterSave, hideDetails: true }),
    ]

    columns = [
        this.gCol('code', 'Matricula', 'text'),
        //this.gCol('registration_date', 'Incorporacion', 'date'),
        this.gCol('document', 'Documento', 'text'),
        this.gCol('fullname', 'Apellidos y Nombres', 'text'),
        this.gCol('condition_message', 'Condicion', 'text'),
        this.gCol('alive', 'Vive?', 'boolean'),
        this.gCol('active', 'Habilitado', 'text'),
        this.defaultActions([
            this.getDefActions.view,
            this.getDefActions.edit,
            { name: 'payment', color: 'success', title: 'Agregar Pago', class: 'mr-1', icon: 'payments', event: this.addPayment },
        ], 130)
    ]

    get extraToolbarButtons() {
        if (this.$vuetify.breakpoint.xsOnly) {
            let vm = this;
            let items = []

            if (this.canCreate())
                items.push({ icon: 'mdi-upload', label: this.L('Importar Nuevos'), click: function () { (vm.showImportNew = !vm.showImportNew) } });

            if (this.canEdit())
                items.push({ icon: 'mdi-upload', label: this.L('Importar Habilitados'), click: function () { (vm.showImport = !vm.showImport) } });

            return items;
        } else
            return null;
    }

    get exluddeIds() {
        let ids = [];
        let ids2 = [];
        this.$store.state.lawyer.list.forEach(item => {
            if (!this.isPayment)
                ids.push(item.id);
        });

        return { payment: ids };
    }

    get isPayment() {
        return Util.abp.session.isAdmin || Util.abp.session.isPayment();
    }

    getInitials(string) {
        return Util.getInitials(string);
    }

    addPayment(item) {
        this.$store.commit('payment/new', { lawyer: item })
        this.paymentModelShow = !this.paymentModelShow;
    }

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        if (list != undefined)
            await list.getpage();
    }     

    mounted() {
        
    }

    created() {
        
    }
}