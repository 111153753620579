































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import PageRequest from '@/store/entities/page-request';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Payment from '@/store/entities/lawyers/payment';
import FormBase from '@/components/helper/form-base.vue';

@Component({
    components: { ModalView, FormBase },
    watch: {
        value: function (val) {
            let obj = (this as any);
            if (val) {
                obj.initForm();
            }
            obj.modalShow = val;
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            }
        }
    }
})

export default class PaymentFormForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    payment: Payment = new Payment();
    modalShow: boolean = false;
    selectedItem: any = null;
    filterForm: boolean = true;
    modalSetting: any = {
        entity: "payment",
        title: 'Pago de Habilitados',
        width: "700px",
        saveText: 'Agregar Pago',
        showForm: false
    };

    items = [
        this.gItem({ type: 'date', prop: 'payment_date', label: 'Fecha de Pago', size: 6, required: true }),
        this.gItem({ type: 'spacer', size: 6 }),
        this.gItem({ type: 'object', prop: 'condition_id', label: 'Condicion', generalType: 'L_CONDITION', required: true, size: 6,  }),
        this.gItem({ type: 'date', prop: 'expiration_date', label: 'Nueva fecha de Expiracion', size: 6, required: true, mask: '####' }),
        this.gItem({ type: 'number', prop: 'amount', label: 'Monto', size: 12, prefixSelect: true, prefixProp: 'currency_id', itemText: 'sign', entity: "currency", class: 'big-size' }),
    ]
    
    async initForm() {
        this.payment = Util.extend(true, {}, this.$store.state.payment.editModel);
        
        await this.getLastCode();
    }

    getInitials(string) {
        return Util.getInitials(string);
    }
    
    async getLastCode() {
        let code = await this.$store.dispatch({ type: 'payment/getLastCode' });
        this.payment.code = code;
    }

    async save() {
        let form = (this.$refs.entityForm as any);
        if (form.validate()) {
            let model = Util.extend(true, {}, this.payment);
            model.lawyer_id = this.payment.lawyer.id;
            
            let newModel = await this.$store.dispatch({
                type: 'payment/create',
                data: model
            });

            this.$emit('save-success');
            Util.abp.notify.success(this, 'Pago registrado, ' + newModel.condition_message);
            this.$emit('input', false);
        }
    }

    cancel() {
        this.$emit('input', false);
    }

    mounted() {

    }

    created() {
    }
}
