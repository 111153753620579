
























































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';

@Component({
    components: { ModalView },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            }
        }
    }
})

export default class ImprotEnabledView extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    file: any = null;
    modalSetting: any = {
        entity: "lawyer",
        width: "95%",
        showForm: false,
        titleFull: 'Importar Nuevos Agremiados',
        icon: 'mdi-upload',
        saveText: 'Importar Agremiados',
        saveIcon: 'mdi-upload',
        disabledSave: true,
        customSave: true,
        persistent: false
    };

    columns = [
        { value: 'nro', text: 'Nro', type: 'text', sortable: false },
        { value: 'code', text: this.L('Nro. Matricula'), type: 'text', sortable: false },
        { value: 'firstname', text: 'Nombres', type: 'text', sortable: false },
        { value: 'lastname1', text: 'Apellido Paterno', type: 'text', sortable: false },
        { value: 'lastname2', text: 'Apellido Materno', type: 'text', sortable: false },
        { value: 'document', text: 'DNI', type: 'text', sortable: false },
        { value: 'email', text: 'Email', type: 'text', sortable: false },
        { value: 'birthdate', text: 'Fecha de Nacimiento', type: 'text', sortable: false },
        { value: 'gender', text: 'Genero', type: 'text', sortable: false },
    ]

    get disabled() {
        if (this.file == null) return true;
        else return false
    }
    get loading() {
        return this.$store.state.lawyer.loading;
    }
    get list() {
        return this.$store.state.lawyer.reportList;
    };
    get totalCount() {
        return this.list.length;
    };

    async importFile() {
        this.modalSetting.disabledSave = true;
        if (this.file == null) return;

        let formData = new FormData();
        formData.append('file', this.file);

        let result = await this.$store.dispatch({
            type: 'lawyer/importNews',
            data: formData
        });

        if (result) {
            if (this.totalCount > 0) {
                this.$toast.info('Primero Revise los datos Importados, <br/>Luego Presione "Importar Agremiados" para completar el proceso', { timeout: 5000 });
                this.modalSetting.disabledSave = false;
            } else
                this.$toast.warning('No se encontraron registros validos', { timeout: 3000 });
        }
    }

    async save() {
        if (this.totalCount > 0) {

            let newModel = await this.$store.dispatch({
                type: 'lawyer/createBatch',
                data: {
                    items: this.list
                }
            });

            this.$toast.success('Los agremiados han sido registrados');
            this.$emit('save-success');
            this.cancel();

            return newModel;
        } else
            return false;
    }

    cancel() {
        this.file = null;
        this.$store.commit('lawyer/setReportList', []);
        this.$emit('input', false);
    }

    mounted() {
    }

    created() {
    }
}
