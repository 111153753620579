

























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FormBase from '@/components/helper/form-base.vue';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Lawyer from '@/store/entities/lawyers/lawyer';

@Component({
    components: { FormBase, ModalView },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.lawyer = Util.extend(true, {}, this.$store.state.lawyer.editModel);
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            } 
        }
    }
})

export default class LawyerForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    lawyer: Lawyer = new Lawyer();
    modalShow: boolean = false;
    modalSetting: any = {
        entity: "lawyer",
        titleFull: 'Agremiado',
        icon: 'visibility',
        width: "700px",
        showForm: false,
        showFootActions: false,
        persistent: false
    };
    

    getInitials(string) {
        return Util.getInitials(string);
    }

    cancel() {
        this.$emit('input', false);
    }

    async mounted() {
    }

    async created() {
    }
}
