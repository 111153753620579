




























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import FormBase from '@/components/helper/form-base.vue';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Lawyer from '@/store/entities/lawyers/lawyer';

@Component({
    components: { FormBase, ModalView },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.lawyer = Util.extend(true, {}, this.$store.state.lawyer.editModel);
                obj.initForm();
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            } else {
                (this as any).initForm();
            }
        }
    }
})

export default class LawyerForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    lawyer: Lawyer = new Lawyer();
    modalShow: boolean = false;
    tabModel: any = null;
    modalSetting: any = {
        entity: "lawyer",
        title: 'Agremiado',
        width: "1000px",
        showForm: false
    };
    
    async getLastCode() {              
        let code = await this.$store.dispatch({
            type: 'lawyer/getLastCode'
        });
        this.lawyer.code = code;
    }
    
    items = [
        this.gItem({ type: 'radio', prop: 'gender_id', label: 'Genero', row: true, generalType: 'T_GENDER', size: 12 }),
        this.gItem({ type: 'object', prop: 'document_type_id', label: 'Tipo de Documento', required: true, size: 3 }),
        this.gItem({ type: 'text', prop: 'document', label: 'Documento', required: true, size: 3 }),
        this.gItem({ type: 'active', size: 3 }),
        this.gItem({ type: 'switch', prop: 'alive', label: 'Vive', size: 3 }),
        this.gItem({ type: 'text', prop: 'firstname', label: 'Nombres', required: true, size: 4 }),
        this.gItem({ type: 'text', prop: 'lastname1', label: 'Apellido Paterno', required: true, size: 4 }),
        this.gItem({ type: 'text', prop: 'lastname2', label: 'Apellido Materno', size: 4 }),
        this.gItem({ type: 'phone', prop: 'phone_mobile', label: 'Numero de Telefono', size: 3 }),
        this.gItem({ type: 'date', prop: 'birthdate', label: 'Fecha de Nacimiento', size: 3 }),
        this.gItem({ type: 'date', prop: 'registration_date', label: 'Fecha de Incorporacion', size: 3 }),
        this.gItem({ type: 'date', prop: 'death_date', label: 'Fecha de Deceso', size: 3 }),
        this.gItem({ type: 'email', prop: 'email', label: 'Email', size: 4 }),
        this.gItem({ type: 'text', prop: 'username', label: 'Usuario', icon:'person', size: 4 }),
        this.gItem({ type: 'password', prop: 'password', label: 'Password', size: 4, hint: 'Minimo 8 caracteres', persistentHint: true }),
        this.gItem({ type: 'divider' }),
        //this.gItem({ type: 'object', prop: 'condition_id', label: 'Condicion', generalType: 'L_CONDITION', size: 3 }),
        //condition_message: string;
        //avatar: string;
    ]

    itemsAddr = [
        this.gItem({ type: 'text', prop: 'address1', label: 'Direccion', preicon: 'home', size: 6 }),
        this.gItem({ type: 'text', prop: 'address2', label: 'Referencia', preicon: 'home', size: 6 }),
        this.gItem({ type: 'object', prop: 'country_id', label: 'Pais', entity: 'country', returnObject: false, size: 3, disabled: true }),
        this.gItem({ type: 'ubigeo', prop: 'ubigeo', entity: 'department', returnObject: true, size: 6, filterParam: 'country_id', filterVal: [0] }),
        this.gItem({ type: 'phone', prop: 'phone', label: 'Telefono', size: 3 }),
    ]

    initForm() {
        this.setItem(this.items, 'active', 'disabled', false);
        let $this = (this as any);
        let model = this.$store.state.lawyer.editModel;
        if (model.id > 0) {                
            this.setItem(this.items, 'active', 'disabled', true);
        } else {
            this.getLastCode();         
        }
    }

    async save() {
        if ((this.$refs.entityForm1 as any).validate()) {
            let model = Util.extend(true, {}, this.lawyer);

            if (model.address != null && model.address.ubigeo != undefined) {
                model.address.department_id = model.address.ubigeo.department;
                model.address.province_id = model.address.ubigeo.province;
                model.address.district_id = model.address.ubigeo.district;
                delete model.address.ubigeo;
            }

            if (model.address_legal != null && model.address_legal.ubigeo != undefined) {
                model.address_legal.department_id = model.address_legal.ubigeo.department;
                model.address_legal.province_id = model.address_legal.ubigeo.province;
                model.address_legal.district_id = model.address_legal.ubigeo.district;
                delete model.address_legal.ubigeo;
            }

            if (model.address_work != null && model.address_work.ubigeo != undefined) {
                model.address_work.department_id = model.address_work.ubigeo.department;
                model.address_work.province_id = model.address_work.ubigeo.province;
                model.address_work.district_id = model.address_work.ubigeo.district;
                delete model.address_work.ubigeo;
            }

            await this.$store.dispatch({
                type: 'lawyer/' + (this.lawyer.id > 0 ? 'update' : 'create'),
                data: model
            });
            this.$emit('save-success');
            this.cancel();
            if (this.lawyer.id > 0)
                Util.abp.notify.success(this, 'Informacion del Agremiado actualizada!');
            else
                Util.abp.notify.success(this, 'El Agremiado ha sido registrado!');
        }
    }        

    cancel() {
        this.$emit('input', false);
    }

    async mounted() {
    }

    async created() {
    }
}
