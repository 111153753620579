












































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';

@Component({
    components: { ModalView },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            }
        }
    }
})

export default class ImprotEnabledView extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    file: any = null;
    modalSetting: any = {
        entity: "lawyer",
        width: "95%",
        showForm: false,
        titleFull: 'Importar Listado de Habilitados',
        icon: 'mdi-upload',
        saveText: 'Actualizar Habilitados',
        saveIcon: 'mdi-upload',
        disabledSave: true,
        customSave: true,
        //showFootActions: false,
        persistent: false
    };

    columns = [
        { value: 'code', text: this.L('Matricula'), type: 'text', sortable: false },
        { value: 'fullname', text: 'Agremiado', type: 'text', sortable: false },
        { value: 'birth_date', text: 'Fecha de Nacimiento', type: 'text', sortable: false },
        { value: 'registration_date', text: 'Incorporacion', type: 'text', sortable: false },
        { value: 'gender', text: 'Genero', type: 'text', sortable: false },
        { value: 'alive', text: 'Vive', type: 'text', sortable: false },
        { value: 'death_date', text: 'Fecha de Fallecimiento', type: 'text', sortable: false },
        { value: 'condition_message', text: 'Condicion', type: 'text', sortable: false },
    ]

    get disabled() {
        if (this.file == null) return true;
        else return false
    }
    get loading() {
        return this.$store.state.lawyer.loading;
    }
    get list() {
        return this.$store.state.lawyer.reportList;
    };
    get totalCount() {
        return this.list.length;
    };

    async importFile() {
        this.modalSetting.disabledSave = true;
        if (this.file == null) return;

        let formData = new FormData();
        formData.append('file', this.file);

        let result = await this.$store.dispatch({
            type: 'lawyer/importEnabled',
            data: formData
        });

        if (result) {
            if (this.totalCount > 0) {
                this.$toast.info('Primero Revise los datos Importados, <br/>Luego Presione "Actualizar Habilitados" para completar el proceso', { timeout: 5000 });
                this.modalSetting.disabledSave = false;
            } else
                this.$toast.warning('No se detectaron cambios en los habilitados', { timeout: 3000 });
        }
    }

    async save() {
        if (this.totalCount > 0) {
            let items = [];
            this.list.forEach(item => {
                items.push({
                    id: item.id,
                    registration_date: item.registration_date_new, 
                    alive: item.alive_new, 
                    birth_date: item.birth_date_new, 
                    death_date: item.death_date_new, 
                    gender: item.gender_new, 
                    condition_message: item.condition_message_new, 
                    address: item.address, 
                    address_legal: item.address_legal, 
                    address_work: item.address_work, 
                });
            });

            let newModel = await this.$store.dispatch({
                type: 'lawyer/updateBatch',
                data: {
                    items: items
                }
            });

            this.$toast.success('Habilitados Actualizados');
            this.$emit('save-success');
            this.cancel();

            return newModel;
        } else
            return false;
    }

    cancel() {
        this.file = null;
        this.$store.commit('lawyer/setReportList', []);
        this.$emit('input', false);
    }

    mounted() {
    }

    created() {
    }
}
